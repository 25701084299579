// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-songs-alphabetical-js": () => import("./../../src/pages/songs/alphabetical.js" /* webpackChunkName: "component---src-pages-songs-alphabetical-js" */),
  "component---src-pages-songs-categories-js": () => import("./../../src/pages/songs/categories.js" /* webpackChunkName: "component---src-pages-songs-categories-js" */),
  "component---src-pages-songs-chronological-js": () => import("./../../src/pages/songs/chronological.js" /* webpackChunkName: "component---src-pages-songs-chronological-js" */),
  "component---src-pages-songs-index-js": () => import("./../../src/pages/songs/index.js" /* webpackChunkName: "component---src-pages-songs-index-js" */),
  "component---src-pages-songs-instrumental-js": () => import("./../../src/pages/songs/instrumental.js" /* webpackChunkName: "component---src-pages-songs-instrumental-js" */),
  "component---src-pages-songs-occasions-js": () => import("./../../src/pages/songs/occasions.js" /* webpackChunkName: "component---src-pages-songs-occasions-js" */),
  "component---src-pages-songs-vocal-js": () => import("./../../src/pages/songs/vocal.js" /* webpackChunkName: "component---src-pages-songs-vocal-js" */),
  "component---src-templates-work-js": () => import("./../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

